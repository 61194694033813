<template>
  <div class="account-orders-wrap">
    <div class="order-table-wrap">
      <data-table
        :items="orders"
        :columns="columns"
        :default-options="{ sortBy: ['orderTime'], sortDesc: [true] }"
        search-placeholder="Suche"
        @dblclick:row="(e, item) => showConfigurator(item.item)"
      >
        <template v-slot:[`item.orderTime`]="{ item }">
          {{ getReadableDate(item.orderTime) }}
        </template>

        <template v-slot:[`item.orderActions`]="{ item }">
          <v-btn icon @click="showConfigurator(item)">
            <v-icon color="primary">
              refresh
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.customerPrice`]="{ item }">
          {{ item.customerPrice | centsToEuro }}
        </template>

        <template v-slot:[`item.retailerPrice`]="{ item }">
          {{ item.retailerPrice | centsToEuro }}
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import OrderApi from '@/api/Order'
import DataTable from '@/components/DataTable'

export default {
  name: 'account-orders',

  components: {
    DataTable,
  },

  data () {
    return {
      orders: [],
    }
  },

  computed: {
    columns () {
      return [
        { value: 'orderTime', text: 'Bestellzeitpunkt' },
        {
          value: this.user.showCustomerPrice ? 'customerPrice' : 'retailerPrice',
          text: this.user.showCustomerPrice ? 'Preis' : 'Fachhändlerpreis',
          align: 'right'
        },
        { value: 'orderActions', text: 'Aktionen', align: 'center' },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },

  mounted () {
    this.loadOrders()
  },

  methods: {
    /**
     * Loads orders of the current user.
     *
     * @returns {void}
     */
    async loadOrders () {
      const res = await OrderApi.getAll()
      res.ok && (this.orders = await res.json())
    },

    getReadableDate (date) {
      return format(parseISO(date), 'dd.MM.yyyy - HH:mm')
    },

    showConfigurator (entry) {
      const { model, frame } = entry.orderConfiguration

      this.$router.push({
        name: 'BikeConfigurator',
        query: { model, frame, order: entry.orderId },
      })
    },
  },
}
</script>
