<template>
  <nav class="desktop-nav">
    <v-btn
      v-for="({ label, route, icon, restricted }, i) in navEntries"
      :key="i"
      small
      text
      :to="{ name: route }"
      :disabled="restricted && user === null"
      class="ml-2"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>

    <v-menu v-if="user" bottom left class="user-nav">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon class="ml-2" v-bind="attrs" v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-for="({ label, route, icon }, i) in userNavEntries" :key="`u_${i}`" :to="{ name: route }">
          <v-list-item-icon class="mr-4">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ label }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon class="mr-4">
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-else small text :to="{ name: 'Login' }" class="ml-2">
      <v-icon left>
        login
      </v-icon>
      Login
    </v-btn>
  </nav>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'desktop-nav',

  props: {
    navEntries: {
      type: Array,
      default: () => ([])
    },
    userNavEntries: {
      type: Array,
      default: () => ([])
    },
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  methods: {
    async logout () {
      const res = await AuthApi.logout()

      if (res.ok) {
        this.$store.commit('setUser', null)
        this.$store.commit('setSnackbar', { text: 'Logout erfolgreich', color: 'success' })
        this.$route.name !== 'Home' && this.$router.push({ name: 'Home' })
      }
    },
  },
}
</script>

<style lang="scss">
  .desktop-nav {
    .v-btn {
      &.v-btn--active::before {
        background-color: #1875d1;
      }
    }
  }
</style>
