<template>
  <div class="series-bikes-details--wrap">
    <template v-if="bikeId && bike">
      <component-img
        v-if="bike.hasImage"
        :id="bike.standardBikeSeriesId"
        :has-image="bike.hasImage"
        :alt="bike.title"
        :height="600"
        is-series
        contain
        class="mb-10"
      />

      <h1>
        {{ bike.title }}
      </h1>

      <v-row v-if="dataLoaded">
        <v-col :cols="12" :lg="4">
          <div
            v-html="bike.description"
            class="mb-10"
          />

          <template v-if="maxAmount > 0 && sortedAvailableVariants.length > 0">
            <plus-minus-input
              v-model="amount"
              :min="minAmount"
              :max="maxAmount"
              @input="getCurrentArticleInfo()"
            />

            <div class="my-2 font-weight-bold">
              Noch {{ maxAmount }} verfügbar
            </div>
          </template>

          <v-btn
            v-if="isBuyable && price"
            depressed
            large
            tile
            color="primary"
            class="mt-8"
            :loading="isLoading"
            :disabled="isLoading"
            @click="showSummaryModal = true"
          >
            <v-icon left>
              shopping_cart
            </v-icon>
            {{ price | centsToEuro }}<sup>*</sup>
            <v-divider vertical class="mx-2" dark />
            Bestellen
          </v-btn>

          <availability-status
            v-if="availabilityStatus"
            :status="availabilityStatus"
            class="mt-2"
          />
        </v-col>

        <v-col v-if="articleVariants.length > 0" :cols="12" :lg="4">
          <v-list two-line>
            <template v-for="(frame, i) in sortedFrames">
              <v-list-item
                :key="frame.standardBikeSeriesFrameId"
                :input-value="frameIsChosen(frame)"
                :disabled="!frameChoosable(frame.standardBikeSeriesFrameId)"
                color="primary"
                @click="chosenFrame = frame"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'font-weight-bold': frameIsChosen(frame)
                    }"
                  >
                    {{ frame.frameSize }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="{
                      'primary--text': frameIsChosen(frame)
                    }"
                  >
                    {{ frame.frameType }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="frameIsChosen(frame)">
                  <v-btn icon>
                    <v-icon color="primary">
                      check
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="i < bike.frames.length - 1"
                :key="`${frame.standardBikeSeriesFrameId}_div`"
              />
            </template>
          </v-list>
        </v-col>

        <v-col v-if="articleVariants.length > 0" :cols="12" :lg="4">
          <v-list two-line>
            <template v-for="(color, x) in sortedColors">
              <v-list-item
                :key="color.standardBikeSeriesColorId"
                :input-value="colorIsChosen(color)"
                :disabled="!colorChoosable(color.standardBikeSeriesColorId)"
                color="primary"
                @click="chosenColor = color"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'font-weight-bold': colorIsChosen(color)
                    }"
                  >
                    {{ color.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="colorIsChosen(color)">
                  <v-btn icon>
                    <v-icon color="primary">
                      check
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="x < bike.colors.length - 1"
                :key="`${color.standardBikeSeriesColorId}_div`"
              />
            </template>
          </v-list>
        </v-col>
      </v-row>

      <v-alert
        v-if="showOrderError"
        color="red darken-2"
        icon="error"
        tile
        outlined
        class="my-4"
      >
        Leider gab es ein Problem. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal. <br>
        Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an <a href="mailto:support@wittich-bikes.de">support@wittich-bikes.de</a>
      </v-alert>
    </template>

    <v-overlay :value="isLoading || !dataLoaded" color="white">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-dialog
      v-model="showSummaryModal"
      width="900"
      max-width="90%"
    >
      <v-card :loading="isLoading">
        <v-card-title>
          Bestellung bestätigen
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item v-if="chosenColor">
              <v-list-item-content>
                <v-list-item-title>
                  {{ chosenColor.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Gewählte Farbe
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item v-if="chosenFrame">
              <v-list-item-content>
                <v-list-item-title>
                  {{ chosenFrame.name }}
                  <template v-if="chosenFrame.frameType">
                    ({{ chosenFrame.frameType }})
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Gewählter Rahmen
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ amount }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Anzahl
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item v-if="price">
              <v-list-item-content>
                <v-list-item-title>
                  {{ price | centsToEuro }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Preis
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-textarea
            v-model="comment"
            label="Kommentar"
            hide-details
            filled
          />
        </v-card-text>

        <v-card-actions class="px-6 pb-4">
          <v-btn
            depressed
            tile
            @click="showSummaryModal = false"
          >
            Abbrechen
          </v-btn>

          <v-spacer />

          <v-btn
            depressed
            tile
            color="primary"
            :loading="isLoading"
            @click="order()"
          >
            Bestellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SeriesApi from '@/api/Series'
import ShopApi from '@/api/Shop'

import ComponentImg from '@/components/ComponentImg'
import AvailabilityStatus from '@/components/AvailabilityStatus.vue'
import PlusMinusInput from '@/components/PlusMinusInput.vue'

export default {
  name: 'series-bikes-details',

  components: {
    AvailabilityStatus,
    ComponentImg,
    PlusMinusInput,
  },

  data () {
    return {
      amount: 1,
      minAmount: 1,
      maxAmount: 1,
      articleVariants: [],
      availabilityStatus: '',
      buyableStati: ['available_instant', 'available_next_day'],
      dataLoaded: false,
      bike: null,
      chosenColor: null,
      chosenFrame: null,
      comment: '',
      isLoading: false,
      price: 0,
      showOrderError: false,
      showSummaryModal: false,
    }
  },

  computed: {
    bikeId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    isBuyable () {
      return this.amount > 0 && this.buyableStati.includes(this.availabilityStatus)
    },

    sortedAvailableVariants () {
      return this.articleVariants
        .filter(({ availabilityStatus }) => this.buyableStati.includes(availabilityStatus))
        .sort((a, b) =>
          a.frame.frameType.localeCompare(b.frame.frameType) ||
          a.frame.frameSize - b.frame.frameSize ||
          a.color.name.localeCompare(b.color.name)
        )
    },

    sortedColors () {
      return this.bike === null
        ? []
        : [...this.bike.colors].sort((a, b) => a.name.localeCompare(b.name))
    },

    sortedFrames () {
      return this.bike === null
        ? []
        : [...this.bike.frames].sort((a, b) => a.frameType.localeCompare(b.frameType) || a.frameSize - b.frameSize)
    },
  },

  watch: {
    chosenColor (_, from) {
      from !== null && this.onChoiceChange()
    },

    chosenFrame (to, from) {
      // initial choice should by fine (since its picked by us)
      if (from === null) {
        return
      }

      const desiredVariant = this.articleVariants.find(({ color, frame }) =>
        frame.standardBikeSeriesFrameId === to.standardBikeSeriesFrameId &&
        color.standardBikeSeriesColorId === this.chosenColor.standardBikeSeriesColorId
      )

      // previously/current chosen color isn't available for the newly chosen
      // frame - so we have to pick another one
      if (!desiredVariant || !this.buyableStati.includes(desiredVariant.availabilityStatus)) {
        const alternativeVariant = this.sortedAvailableVariants.find(({ frame }) => frame.standardBikeSeriesFrameId === to.standardBikeSeriesFrameId)
        alternativeVariant && (this.chosenColor = alternativeVariant.color)
      }

      this.onChoiceChange()
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      await Promise.all([
        this.loadBike(),
        this.getArticleVariants(),
      ])

      this.dataLoaded = true
      this.setInitialChoice()
    },

    /**
     * loadBike
     *
     * @returns {Promise}
     */
    async loadBike () {
      if (this.bikeId) {
        const res = await SeriesApi.get(this.bikeId)
        res.ok && (this.bike = await res.json())
      }
    },

    /**
     * Initially we want to preselect a frame and a color. But it's possible
     * that combinations aren't currently available - so we have to pick the
     * first buyable combination.
     *
     * @returns {undefined}
     */
    setInitialChoice () {
      if (this.sortedAvailableVariants.length > 0) {
        this.chosenColor = this.sortedAvailableVariants[0].color
        this.chosenFrame = this.sortedAvailableVariants[0].frame
        this.onChoiceChange()
      } else {
        this.availabilityStatus = 'not_available'
      }
    },

    /**
     * When the user picks a new article-variant (combination of a frame and a
     * color) we have to get its price and the status/availability.
     *
     * @returns {undefined}
     */
    async onChoiceChange () {
      if (!this.bike || !this.chosenFrame || !this.chosenColor) {
        return
      }

      this.amount = 1
      this.getCurrentArticleInfo()
    },

    /**
     * The bike-dataset has the lists of related frames and colors, but those
     * don't have the availability-information (it's possible that combinations
     * aren't available at the moment). So we have to load the variants with
     * their status too.
     *
     * @returns {Promise}
     */
    async getArticleVariants () {
      const res = await ShopApi.getPrices(this.bikeId)
      res.ok && (this.articleVariants = await res.json())
    },

    /**
     * getCurrentArticleInfo
     *
     * @returns {Promise}
     */
    async getCurrentArticleInfo () {
      this.isLoading = true

      const res = await ShopApi.getPrice(
        this.bike.standardBikeSeriesId,
        this.chosenColor.standardBikeSeriesColorId,
        this.chosenFrame.standardBikeSeriesFrameId,
        this.amount
      )

      this.isLoading = false

      if (res.ok) {
        const { price, availabilityStatus, freeAmount } = await res.json()

        this.price = price
        this.availabilityStatus = availabilityStatus
        this.maxAmount = freeAmount
      }
    },

    /**
     * order
     *
     * @returns {Promise}
     */
    async order () {
      this.isLoading = true

      const res = await ShopApi.createOrder(
        this.bike.standardBikeSeriesId,
        this.chosenColor.standardBikeSeriesColorId,
        this.chosenFrame.standardBikeSeriesFrameId,
        this.amount,
        this.comment,
      )

      this.showSummaryModal = false
      this.isLoading = false
      this.comment = ''
      this.amount = this.minAmount

      if (res.ok) {
        this.$store.commit('setSnackbar', { text: 'Bestellung erfolgreich', color: 'success' })
        await this.getArticleVariants()
        return this.getCurrentArticleInfo()
      }

      this.showOrderError = true
      this.$store.commit('setSnackbar', { text: 'Bestellung konnte nicht abgeschlossen werden, probieren sie es später noch einmal oder wenden sie sich an den Support', color: 'error' })
    },

    /**
     * Checks if the given color-id is available/choosable for the currently
     * selected frame.
     *
     * @param {number} colorId
     * @returns {boolean}
     */
    colorChoosable (colorId) {
      return this.articleVariants.length && this.chosenFrame && this.articleVariants.filter(variant =>
        variant.frame.standardBikeSeriesFrameId === this.chosenFrame.standardBikeSeriesFrameId &&
        variant.color.standardBikeSeriesColorId === colorId &&
        this.buyableStati.includes(variant.availabilityStatus)
      ).length > 0
    },

    /**
     * Checks if the given color is currently chosen.
     *
     * @param {object} color
     * @returns {boolean}
     */
    colorIsChosen (color) {
      return this.chosenColor && this.chosenColor.standardBikeSeriesColorId === color.standardBikeSeriesColorId
    },

    /**
     * Checks if the frame with the given id is currently available, choosable.
     *
     * @param {number} frameId
     * @returns {boolean}
     */
    frameChoosable (frameId) {
      return this.articleVariants.length && this.articleVariants.filter(variant =>
        variant.frame.standardBikeSeriesFrameId === frameId &&
        this.buyableStati.includes(variant.availabilityStatus)
      ).length > 0
    },

    /**
     * Checks if the given frame is currently chosen.
     *
     * @param {object} frame
     * @returns {boolean}
     */
    frameIsChosen (frame) {
      return this.chosenFrame && this.chosenFrame.standardBikeSeriesFrameId === frame.standardBikeSeriesFrameId
    },
  },
}
</script>

<style lang="scss">
  .series-bikes-details--wrap {
    .amount-input {
      border-radius: 0;
      max-width: 200px;

      label {
        color: #212121;
      }

      fieldset {
        border-color: #212121;
      }
    }
  }
</style>
