var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"desktop-nav"},[_vm._l((_vm.navEntries),function(ref,i){
var label = ref.label;
var route = ref.route;
var icon = ref.icon;
var restricted = ref.restricted;
return _c('v-btn',{key:i,staticClass:"ml-2",attrs:{"small":"","text":"","to":{ name: route },"disabled":restricted && _vm.user === null}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(icon)+" ")]),_vm._v(" "+_vm._s(label)+" ")],1)}),(_vm.user)?_c('v-menu',{staticClass:"user-nav",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2717533888)},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.userNavEntries),function(ref,i){
var label = ref.label;
var route = ref.route;
var icon = ref.icon;
return _c('v-list-item',{key:("u_" + i),attrs:{"to":{ name: route }}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)],2)],1):_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":"","to":{ name: 'Login' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" login ")]),_vm._v(" Login ")],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }