var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-orders-wrap"},[_c('div',{staticClass:"order-table-wrap"},[_c('data-table',{attrs:{"items":_vm.orders,"columns":_vm.columns,"default-options":{ sortBy: ['orderTime'], sortDesc: [true] },"search-placeholder":"Suche"},on:{"dblclick:row":function (e, item) { return _vm.showConfigurator(item.item); }},scopedSlots:_vm._u([{key:"item.orderTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadableDate(item.orderTime))+" ")]}},{key:"item.orderActions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showConfigurator(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" refresh ")])],1)]}},{key:"item.customerPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.customerPrice))+" ")]}},{key:"item.retailerPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.retailerPrice))+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }